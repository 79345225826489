/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* Classic Bootstrap : remove if not needed */
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import './src/styles/global.scss';

// Load typeface
require('typeface-roboto');
require('typeface-source-sans-pro');
require('typeface-asap');
